.project-page-menu-bar-container {
  height: 8%;
  min-height: 90px;
  overflow: hidden;
  width: inherit;
  margin-top: 10px;
}

.project-page-menu-bar {
  background-color: rgba(34, 38, 41, 1);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: white;
  border: 4px solid;
  border-color: rgba(36, 170, 126, 1);
  box-sizing: border-box;
  padding-inline: 2%;
  border-radius: 10px;
  height: 100%;
}

.project-page-buttons-loading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 33%;
}

#project-loading-icon {
  margin-left: 20px;
}

.project-loading-icon-hide {
  display: none!important;
}

.project-page-project-title {
  position: absolute;
  left: 50%; 
  transform: translateX(-50%);
  width: 40%;
  word-wrap: break-word;
}

.decrease-step-1 {
  font-size: 1.5rem!important;
}

.project-page-project-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-page-project-buttons-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 20px;
  box-sizing: border-box;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.project-page-add-column-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  height: 100%;
  justify-content: flex-end;
  width: 33%;
}

.no-columns-add-column-button {
  box-shadow: 5px 5px 10px 5px rgba(45, 106, 79, 0.8) !important;
}



