.hidden {
    display: none !important;
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
  
  .filter-modal {
    background-color: #2d6a4f;
    padding: 20px;
    border-radius: 8px;
    width: 50%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
  }
  
  .filter-modal-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .filter-modal-title {
    margin: 0 auto; /* Adds automatic margins to the left and right */
    display: block; /* Ensure it's a block-level element */
    width: 80%; /* Example width */
  }
  
  
  .filter-modal-close-btn {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .filter-modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start for consistent alignment */
  }
  
  .filter-modal-checkbox {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Vertically center the checkbox and label */
    margin: 10px 0;
  }
  
  .filter-modal-checkbox input[type="checkbox"] {
    margin-right: 10px; /* Consistent spacing between the checkbox and the label */
  }
  
  .filter-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .filter-modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px;
    transition: background-color 0.3s ease;
  }
  
  .filter-modal-button-primary {
    background-color: #81b29a;
    color: white;
  }
  
  .filter-modal-button-primary:hover {
    background-color: #679267;
  }
  
  .filter-modal-button-secondary {
    background-color: #e63946;
    color: white;
  }
  
  .filter-modal-button-secondary:hover {
    background-color: #d62828;
  }
  
  @media (max-width: 600px) {
    .filter-modal {
      width: 80%;
      padding: 10px;
    }
  }
  