.project-management-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1b4332;
  max-height: inherit;
  height: inherit;
  width: inherit;
}

.add-task-content-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: auto;
  gap: 20px;
  width: 100%;
  height: -webkit-fill-available;
  height: fill-available;
  height: 100%;
}


.icon {
  cursor: pointer;
}

/**
 * Alert styles for deleting a project, or task, or column
 **/
.alert-delete-member,
.alert-delete-sprint,
.alert-delete-comment,
.alert-delete-task,
.alert-delete-project {
  background: rgba(198, 128, 63, .7);
  border-left: 5px solid rgba(229, 90, 60, 0.95);
  border-right: 5px solid rgba(229, 90, 60, 0.95);
}

.alert-delete-task-dialog-container > .MuiDialog-container {
  width: 100%;
}

.alert-delete-task-dialog-container > .MuiDialog-paper {
  width: 100%;
}

#alert-delete-member-dialog-title,
#alert-delete-sprint-dialog-title,
#alert-delete-comment-dialog-title,
#alert-delete-task-dialog-title,
#alert-delete-project-dialog-title {
  border-top: 5px solid rgba(229, 90, 60, 0.95);
}

#alert-delete-task-dialog-actions {
  gap: 10px;
}

#alert-delete-sprint-dialog-actions,
#alert-delete-member-dialog-actions,
#alert-delete-task-dialog-actions,
#alert-delete-project-dialog-actions {
  border-bottom: 5px solid rgba(229, 90, 60, 0.95);
}

#alert-dialog-confirmation {
  margin-top: 10px;
  border-bottom: 5px solid rgba(229, 90, 60, 0.95);
  border-top: 5px solid rgba(229, 90, 60, 0.95);
  font-weight: 800!important;
}


/**
 * Scroll bar styling
 **/

::-webkit-scrollbar {
  width: 5px;
  padding-left: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

