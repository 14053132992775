html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1b4332;
}

.username-setup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: #1b4332;
  color: white;
}

.username-setup-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  padding: 20px;
  border-radius: 8px;
  background-color: #2d6a4f;
  color: white;
}

.username-setup-form input[type="text"], .username-setup-form button {
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 4px;
  font-weight: bold; /* Ensure buttons and inputs are bold */
}

.username-setup-form button:hover {
  background-color: #679267;
}

/* Error styling adjusted to be consistent with potential SplashPage styling */
.error-message {
  color: #e63946; /* Consistent error color */
  margin-bottom: 10px;
}

.input-error {
  border: 2px solid #e63946; /* Red border to indicate error */
}

.username-input.input-error, .username-input.input-error:focus {
  border: 2px solid #e63946; /* Explicit focus styling to ensure visibility */
}

.username-input.input-error {
  border: 2px solid #e63946 !important; /* Increase specificity and optionally use !important */
}