html {
  height: 100vh;
  width: 100vw;
}

body, #root, .App, .user-homepage-container {
  height: inherit;
  width: inherit;
}

.user-homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1b4332;
  gap: 20px;
}

.user-homepage-header {
  box-sizing: border-box; /* Include padding in width calculations */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75px;
  background-color: #2d6a4f;
  border-radius: 8px;
  color: white;
  padding-left: 40px;
  padding-right: 40px;
}


.user-homepage-header img {
  width: 100px;
  height: auto;
  margin-right: 0px;
}

.user-homepage-buttons {
  display: flex;
  gap: 10px;
  padding-inline-end: 1%;
}

.user-homepage-buttons input[type="text"] {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #a5becc;
  color: #333;
}

.user-homepage-buttons input[type="text"]:focus {
  outline: none;
  box-shadow: 0 0 0 2px #81b29a;
}

.user-homepage-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  background-color: #81b29a;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

.user-homepage-buttons button:hover {
  background-color: #679267;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.error-message {
  color: #ff6b6b;
  padding-left: 15px;
}

.content-container {
  display: flex;
  gap: 20px;
  width: 100%;
  flex: 1; /* This makes sure your content container can expand */
  overflow: hidden; /* Prevents overflow from messing layout */
}

.team-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1; /* Allows the team list to grow and fill available space */
  background-color: #2d6a4f;
  padding: 20px;
  border-radius: 8px;
  color: white;
  overflow-y: auto; /* Keeps vertical scrolling */
}

/* Hide delete button by default */
.team-card .delete-button {
  right: 10px; /* Adjust as needed to align with the card's edge */
  top: 10px; /* Adjust as needed for vertical alignment */
  display: none;
  padding: 1px 3px; /* Adjust padding as needed */
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  background-color: #e63946;;
  color: white;
}

/* Show delete button on hover */
.team-card:hover .delete-button {
  display: block;
}

.team-card {
  background-color: #81b29a; /* Replace with your card's color */
  border-radius: 8px;
  padding: 0px 0px;
  margin-bottom: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Adjust for shadow */
  transition: all 0.3s ease; 
} 

.team-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.team-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-button {
  background: transparent;
  border: none;
  color: white;
  text-align: left;
  padding: 0;
  font-size: 1.25rem;
}

.team-button {
  background: transparent;
  border: none;
  color: white;
  text-align: center;
  padding: 10px 15px; /* Adjust padding as needed */
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
  width: 100%; 
  cursor: pointer; /* This line changes the cursor to a pointer on hover */
}


.delete-button {
  background-color: #e63946;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
}

.team-details {
  display: none; /* Hide team details by default */
}

.team-card.selected .team-details {
  display: block; /* Show details when team card is selected */
  background-color: #313a3d9a; /* edit this*/ 
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
}

/* Add hover effect to match project cards */
.team-button:hover {
  background-color: #679267; /* Slightly darker shade for hover effect */
}


.close-button {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  background-color: #e74c3c;
  color: white;
  font-size: 16px;
  z-index: 2;
}

.close-button:hover {
  background-color: #d63031;
}

.team-card.selected .close-button {
  display: block;
}

.input-error {
  border: 1px solid red;
}

.error-message {
  color: red;
  margin-top: 8px;
}

 .team-button {
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 12px;
  margin: 2px;
}

.team-actions button, .delete-button {
  font-size: 1rem;
  font-weight: 600;
  padding: 8px 12px;
  margin: 2px;
  text-transform: uppercase;
}

.team-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.team-members-list {
  margin-bottom: 10px;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 3;
  background-color: #2d6a4f;
  padding: 20px;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto; /* Keeps vertical scrolling */

}

.project-card {
  display: flex;
  justify-content: space-between; /* Aligns children to each end */
  align-items: center; /* Centers children vertically */
  background-color: #81b29a;
  padding: 40px;
  border-radius: 8px;
  margin-bottom: 10px;
  color: white;
}

.project-name, .team-name {
  margin: 0 30px; /* Adds margin to the left and right */
}


.project-name h3, .team-name h3 {
  margin: 0; /* Removes default margin */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Ensures text does not overflow */
}

.project-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.project-card h3 {
  margin: 0;
  color: #ffffff;
  font-size: 1.25rem;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-header h2 {
  visibility: hidden; /* To retain matching column alignment*/
}

/* Override to make the modal h2 visible */
.filter-modal-title {
  visibility: visible !important;
}


.add-project-input {
  flex: 1;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 4px;
  color: #333;
}



.team-header, .project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Adjust padding as needed */
}

.add-team-button, .add-project-button, .filter-projects-button {
  font-size: 1rem;
  padding: 10px 20px;
  background-color: #81b29a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-team-button:hover, .add-project-button:hover, .filter-projects-button:hover {
  background-color: #679267;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.add-team-button:active, .add-project-button:active {
  background-color: #567356;
  transform: translateY(1px);
  box-shadow: none;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
}


.delete-button:hover {
  background-color: #d62828; /* Darker red for hover */
}


.team-member {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 5px;
  background-color: #81b29a;
  border-radius: 4px;
  color: white;
}

.remove-member-button {
  display: none; /* Hide the button by default */
  padding: 5px 10px;
  background-color: #e63946;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.team-member:hover .remove-member-button {
  display: inline-block; /* Or "block", depending on your layout */
}


.remove-member-button:hover {
  background-color: #d62828;
}

.team-card-selected {
  background-color: #313a3d9a; 
}