.single-comment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
  margin-bottom: 5%;
  border: 2px solid;
  border-color: rgba(36, 170, 126, 0.4);
  border-radius: 10px;
  padding: 2%;
  background-color: rgba(34, 38, 41, 0.9);
  font-size: 0.9rem;
}

.comment-body {
  display: flex;
  flex-direction: row;
  padding-inline: 2%;
  width: 95%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.comment-footer,
.comment-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.comment-footer {
  font-size: 0.8rem;
  padding-top: 1%;
  width: 100%;
}

.comment-owner-button-container {
  display: flex;
  flex-direction: row-reverse;
  width: 20%;
  justify-content: space-between;
}

.comment-header {
  padding-bottom: 1%;
  width: 95%;
}

.comment-divider {
  border-width: 1px;
  border-color: rgba(36, 255, 126, 0.1);
  width: 100%;
}

.comment-edit-delete-icons:hover {
  cursor: pointer;
  color: white;
}

.alert-delete-comment {
  background: rgba(198, 128, 63, .7);
  border-left: 5px solid rgba(229, 90, 60, 0.95);
  border-right: 5px solid rgba(229, 90, 60, 0.95);
}

#alert-delete-comment-dialog-title {
  border-top: 5px solid rgba(229, 90, 60, 0.95);
}

#alert-delete-comment-dialog-actions {
  border-bottom: 5px solid rgba(229, 90, 60, 0.95);
}

#alert-dialog-confirmation {
  margin-top: 10px;
  border-bottom: 5px solid rgba(229, 90, 60, 0.95);
  border-top: 5px solid rgba(229, 90, 60, 0.95);
  font-weight: 800!important;
}

.modify-comment-icon:hover {
  filter: brightness(85%);
}
