.project-content-container {
  display: flex;
  justify-content: left;
  flex-wrap: nowrap;
  width: 100%;
  height: -webkit-fill-available;
  height: fill-available;
  height: 100%;
}

.columns-container {
  display: flex;
  justify-content: left;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
  width: 100%;
  height: -webkit-fill-available;
  height: fill-available;
  height: 100%;
  margin-top: 5px;
}

.sprint-member-container {
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 400px;
  height: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  border-left: 4px rgba(34, 38, 41, 1) solid;
  background-color: rgba(34, 38, 41, 0.4);
}

.columns-error-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.columns-loading-error {
  border: 2px red solid;
  border-radius: 10px;
  padding: 10px;
  width: 30%;
  background: rgba(234, 238, 241, 0.5);
}

/**
 * Members + Sprints CSS
 **/

.members-container,
.sprints-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50%;
  /* Subtract padding-left from parent, border, and inline padding */
  width: calc(100% - 4px); 
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-inline: 2px;
  gap: 5px;
}

.members-container-title,
.sprints-container-title {
  background: rgba(34, 38, 41, 0.7);
  padding: 5px;
  border-radius: 10px;
  width: 95%;
  border: 2px rgba(36, 170, 126, 0.5) solid;
}

.members-title,
.sprints-title {
  color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
}

.members-inner-container,
.sprints-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 80%;
  gap: 5px;
}

.members-output-container,
.sprint-output-container {
  min-height: 0%;
}

.sprint-output-container,
.members-output-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 95%;
  overflow-y: auto;
  /*border: 2px rgba(43, 79, 66, 1) solid;*/
  background: rgba(43, 79, 66, 0.8);
  border-radius: 8px;
  align-items: center;
  border: 2px rgba(36, 170, 126, 0.5) solid;
}

.single-member-container,
.single-sprint-container {
  display: flex;
  align-items:  flex-start;
  border: 2px rgba(29, 53, 44, 0.7) solid;
  border-radius: 8px;
  background: rgba(29, 53, 44, 0.7);
  color: rgba(255, 255, 255, 0.85);
}

.single-sprint-container {
  flex-direction: column;
  justify-content: flex-start;
  margin: 8px;
  padding: 4px;
  width: 90%;
}

.single-member-container {
  width: 93%;
  margin: 2px;
  margin-top: 4px;
  padding-inline: 4px;
  flex-direction: row;
  justify-content: space-between;
}


.single-member-container:hover,
.single-sprint-container:hover {
  background: rgba(29, 53, 44, 0.4);
}

.single-sprint-date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.sprints-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.add-member-button-container,
.add-sprint-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.add-member-button-container,
.add-sprint-button-container,
.add-sprint-container {
  width: 90%;
  align-items: center;
}

.add-members-container,
.add-sprint-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  display: none;
  max-height: 100%;
  overflow: hidden;
}

.add-members-container-show,
.add-sprint-container-show {
  display: flex!important;
}

.add-sprint-dates-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
} 

.show-add-members-container-animate,
.show-add-sprint-container-animate {
  animation: slideInSprintAnimation ease-out 0.4s forwards;
}

@keyframes slideInSprintAnimation {
  from {
    top: var(++h);
    height: 0%;
    opacity: 0;
  }
  to {
    top: 0;
    height: 100%;
    opacity: 1;
  }
}

.hide-add-member-container-animate,
.hide-add-sprint-container-animate {
  animation: slideOutSprintAnimation 0.4s ease-in-out forwards;
}

@keyframes slideOutSprintAnimation {
  from {
    height: 100%;
    opacity: 1;
    top: 0;
  }
  to {
    top: var(--h);
    height: 0%;
    opacity: 0;
  }
}

.members-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.sprint-title-and-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.sprint-icons-container {
  display: flex;
  flex-direction: row;
  width: 20%;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.member-icons-container {
  display: none!important;
}

.show-member-icons-container,
.show-sprint-icons-container {
  display: flex!important;
}

.sprint-member-icon:hover {
  color: white;
}

.add-members-name-field > .Mui-focused,
.add-sprint-name-field > .Mui-focused {
  color: black!important;
}

.add-members-name-field,
.add-sprint-name-field {
  background: rgba(129, 178, 154, 0.85);
  border-radius: 4px;
  width: 100%;
}

.add-members-name-field:hover,
.add-sprint-name-field:hover {
  background: rgba(129, 178, 154, 1);
  border-radius: 4px;
}

.new-sprint-date input {
  color: rgba(255, 255, 255, 0.85);
}

.new-sprint-date label {
  color: rgba(255, 255, 255, 0.5);
}

.new-sprint-date > .Mui-focused > .MuiOutlinedInput-notchedOutline {
  border-color: rgba(129, 255, 154, 0.6)!important;
}

.new-sprint-date > .Mui-focused {
  color: rgba(129, 255, 154, 0.8)!important;
}

.invalid-sprint-name > .Mui-focused {
  color: red!important;
}

.general-sprint-error {
  width: 80%;
  background: rgba(136, 8, 8, 0.4);
  border: 2px rgba(210, 43, 43, 0.8) solid;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.8);

}
