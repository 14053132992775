html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #1b4332; /* Optional: Set the background color here for consistency */
}

.splash-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    background-color: #1b4332;
    color: white;
  }
  
  .splash-container img {
    width: 200px;
    height: auto;
    max-width: 100%;
    margin-top: 20px; /* Match this with the margin in UsernameSetup */
    margin-bottom: 20px; /* Match this with the margin in UsernameSetup */
  }
  

/* Adjust the .centered-content to ensure it allows for a column layout within the .splash-container */
.centered-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure it takes the full width of its parent */
}

.auth-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px; /* Adjust or remove as needed to control spacing */
}

.google-sign-in, .github-sign-in {
    padding: 10px 20px;
    border-radius: 2px;
    font-weight: bold;
    cursor: pointer;
    margin: 5px;
    border: none; /* Ensure buttons do not have a border */
}

.google-sign-in {
    background-color: #4285F4;
    color: white;
}

.github-sign-in {
    background-color: #333;
    color: white;
}