.task-summary-container {
  background: rgba(34, 38, 41, 0.4);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  border: 3px rgba(34, 38, 41, 0.9) solid;
  padding: 8px;
  color: rgba(213, 216, 219, 1);
}

.task-summary-due-date-comments-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
}

.task-summary-title {
  word-wrap: normal;
  word-break: break-all;
}

.task-summary-container-hover-border {
  animation: hoverBorderAnimation ease-out 0.15s forwards;
}

.task-summary-container-unhover-border {
  animation: unHoverBorderAnimation ease-out 0.15s forwards;
}

@keyframes hoverBorderAnimation {
  from {
    border: 3px rgba(34, 38, 41, 0.9) solid;
  }
  to {
    border: 3px rgba(163, 255, 235, 0.45) solid;
  }
}

@keyframes unHoverBorderAnimation {
  from {
    border: 3px rgba(163, 255, 235, 0.45) solid;
  }
  to {
    border: 3px rgba(34, 38, 41, 0.9) solid;
  }
}

.show-task-summary-icon-container {
  display: flex!important;
  animation: slideInAnimation ease-out 0.1s forwards;
}

@keyframes slideInAnimation {
  from {
    margin-top: -5%;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}

.hide-task-summary-icon-container {
  display: flex!important;
  animation: slideOutAnimation 0.1s ease-in forwards;
}

@keyframes slideOutAnimation {
  from {
    margin-top: 0;
    opacity: 1;
  }
  to {
    margin-top: -5%;
    opacity: 0;
  }
}


.task-summary-icon-menu-bar-container {
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}

.task-summary-move-task-icon-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 20%;
  justify-content: space-between;
}

.task-summary-move-icons:hover {
  cursor: pointer;
  color: gray;
}

.task-summary-edit-delete-icon-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 25%;
  justify-content: space-between;
}

.task-summary-edit-delete-icons:hover {
  cursor: pointer;
  color: white;
}
