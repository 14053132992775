.task-details-content-container {
  display: flex;
  flex-direction: row;
  width: 92%;
  height: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: stretch;
  border: 2px rgba(36, 170, 126, 1) solid;
  background-color: rgba(34, 38, 41, 0.7);
  color: rgba(240, 234, 214, 0.8);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  overflow-y: hidden;
}

.task-details-inner-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-inline: 1%;
  gap: 5px;
  overflow-y: auto;
}

.task-details-loading-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.priority-due-date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sprint-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.inner-sprint-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.assigned-to-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.priority-due-date-container,
.sprint-container,
.assigned-to-container,
.description-container {
  width: calc(100% - 4% - 4px);
  border: 2px solid;
  border-color: rgba(36, 170, 126, 0.4);
  border-radius: 10px;
  padding: 2%;
  background-color: rgba(34, 38, 41, 0.9);
}

.description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 50%;
}
 
#task-detail-divider {
  border-width: 1px;
  border-color: rgba(36, 170, 126, 0.1);
  border-radius: 2px;
}

.task-details {
  display: flex;
}

.italic,
.not-filled {
  font-style: italic;
}

.comments-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  justify-content: space-between;
  margin-inline: 1%;
}

.comments-and-add-comment-field-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  justify-content: flex-start;
  align-items: center;
}

.inner-comments-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
  width: 100%;
  padding-top: 3%;
  height: 100%;
}

.add-comment-button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3%;
  justify-content: center;
  align-items: center;
  padding-top: 2%;
  gap: 10px;
}

.add-comment-button {
  width: 75%;
}

.add-comment-field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.add-comment-field {
  background: rgba(129, 178, 154, 0.85);
  border-radius: 4px;
  width: 100%;
  display: none;
}

.add-comment-field:hover {
  background: rgba(129, 178, 154, 1);
  border-radius: 4px;
}

.add-comment-field > .Mui-focused {
  color: black!important;
}

.show-add-comment-field {
  display: flex!important;
  animation: slideInCommentAnimation ease-out 0.4s forwards;
}

@keyframes slideInCommentAnimation {
  from {
    top: var(++h);
    height: 0;
    opacity: 0;
  }
  to {
    top: 0;
    height: 100%;
    opacity: 1;
  }
}

.hide-add-comment-field {
  display: flex!important;
  animation: slideOutCommentAnimation 0.4s ease-in forwards;
}

@keyframes slideOutCommentAnimation {
  from {
    top: 0;
    height: 100%;
    opacity: 1;
  }
  to {
    top: var(++h);
    height: 0;
    opacity: 0;
  }
}
